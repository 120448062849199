<template>
  <div class="introducePage">
    <div class="section-aside">
      <p>为爱加油</p>
      <p>为家护航</p>
    </div>
    <div class="section-main">
      <div class="main-content">
        <p>
          2013年9月，在宁波市这片社会组织发展的沃土上，一个新的社会组织孵化产生——“宁静港湾”婚姻家庭服务中心。这是宁波市妇联海曙区妇联出资建立起来以妇女、儿童和家庭为主要服务对象的非营利的公益性社会组织。2017年1月原宁波市委书记郑栅杰在视察中心时说，“海定则波宁、宁静则港湾，宁静港湾名称非常具有宁波特色。”郑书记寄予中心厚望，希望我们成为宁波家庭服务的标兵。十年时间，你只要关注各类媒体，就能发现“宁静港湾”婚姻家庭服务中心的活跃与发展，中心的成立开创了全省乃至全国妇联组织家庭工作的新模式，这一温暖的“港湾”正日益走入千家万户。宁静港湾婚姻家庭服务中心先后荣获市十佳婚姻家庭调解组织、第五届宁波慈善“最具影响力的慈善项目”、2017年荣获4A级社会组织荣誉称号。
        </p>
      </div>

      <div class="main-video">
        <!-- <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="https://wjk.zhuneng.cn/data/minio/leo/upload/20230226/%E4%B8%BA%E7%88%B1%E5%8A%A0%E6%B2%B9%E4%B8%BA%E5%AE%B6%E6%8A%A4%E8%88%AA%E5%BC%A0%E9%A6%A8%E5%A7%9A_1677378859109.m4a"
        ></audio> -->
        <audio
          controls
          id="audios"
          height="50"
          autoplay="autoplay"
          src="./audio/introduce_bg.mp3"
        ></audio>

        <!-- <div class="infoBox">
          <div class="main-l">
            <div class="video">
              <div
                id="box"
                v-if="detailInfo.streamUrl"
                width="100%"
                height="100%"
              ></div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="main-picture">
        <img src="./img/introducePage_show_one.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePage_show_two.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePage_show_three.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePage_show_four.jpg" alt="" />
      </div>

      <div class="main-picture">
        <img src="./img/introducePage_show_five.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      detailInfo: {
        streamUrl:
          "https://wjk.zhuneng.cn/data/minio/leo/upload/20230226/%E4%B8%BA%E7%88%B1%E5%8A%A0%E6%B2%B9%E4%B8%BA%E5%AE%B6%E6%8A%A4%E8%88%AA%E5%BC%A0%E9%A6%A8%E5%A7%9A_1677378859109.m4a",
      },
    };
  },
  created() {
    // this.timer = setInterval(() => {
    // this.playVideo();
    this.init();
    // document.addEventListener("visibilitychange", this.playFunc);
    // }, 100);
    // document.addEventListener("touchstart", this.playFunc);
  },
  beforeDestroy() {
    // document.removeEventListener("touchstart", this.playVideo);
  },
  methods: {
    init() {
      const that = this;
      //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      document.addEventListener("WeixinJSBridgeReady", function () {
        that.$nextTick(() => {
          document.getElementById("audios").play();
        });
      });
    },
    // playFunc() {
    //   // if (document.visibilityState === "visible") {
    //   this.player.play();
    //   // } else {
    //   // this.player.pause();
    //   // }
    // },
    // init() {
    //   let _this = this;
    //   document.getElementById("box").innerHTML = `<div
    //         id="J_prismPlayer"
    //         width="100%"
    //         height="100%"
    //         class="prism-player"
    //       ></div>`;
    //   this.player = new window.Aliplayer(
    //     {
    //       id: "J_prismPlayer", // 容器id
    //       source: _this.detailInfo.streamUrl, // 视频url
    //       width: "100%", // 播放器宽度
    //       height: "100%", // 播放器高度
    //       autoplay: true,
    //       isLive: true,
    //       rePlay: true,
    //       playsinline: true,
    //       preload: true,
    //       controlBarVisibility: "hover",
    //       useH5Prism: true,
    //     },
    //     function (player) {
    //       console.log("播放器创建了");
    //     }
    //   );
    // },
    // init() {
    //   var audio = document.getElementById("audio");
    //   /** 苹果自动播放音频啥解决方案 */
    //   wxjssdk.config({
    //     debug: false,
    //     appId: "wx69cb21224fb494fb",
    //     timestamp: "",
    //     nonceStr: "",
    //     signature: "",
    //     jsApiList: [],
    //   });
    //   // 上面配置错误也无所谓的，即使配置失败，也会走 ready 函数
    //   // 主要目的就是为了走 ready 函数
    //   wxjssdk.ready(() => {
    //     console.log("触发了----");
    //     // 取得播放器对象，调用播放事件
    //     audio.play();
    //   });
    //   /**end */
    //   //--创建页面监听，等待微信端页面加载完毕 触发音频播放
    //   document.addEventListener("DOMContentLoaded", function () {
    //     function audioAutoPlay() {
    //       audio.play();
    //       document.addEventListener(
    //         "WeixinJSBridgeReady",
    //         function () {
    //           audio.play();
    //         },
    //         false
    //       );
    //     }
    //     audioAutoPlay();
    //   });
    //   //--创建触摸监听，当浏览器打开页面时，触摸屏幕触发事件，进行音频播放
    //   document.addEventListener("touchstart", function () {
    //     function audioAutoPlay() {
    //       var audio = document.getElementById("audio");
    //       audio.play();
    //     }
    //     audioAutoPlay();
    //   });
    // },
    playVideo() {
      // //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      // document.addEventListener("DOMContentLoaded", function () {
      //   function audioAutoPlay() {
      //     var audio = document.getElementById("audio");
      //     audio.play();
      //     document.addEventListener(
      //       "WeixinJSBridgeReady",
      //       function () {
      //         audio.play();
      //       },
      //       false
      //     );
      //   }
      //   audioAutoPlay();
      // });
      // //--创建触摸监听，当浏览器打开页面时，触摸屏幕触发事件，进行音频播放
      // document.addEventListener("touchstart", function () {
      //   function audioAutoPlay() {
      //     var audio = document.getElementById("audio");
      //     audio.play();
      //   }
      //   audioAutoPlay();
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.introducePage {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: 378px 0 80px;
  background: url(./img/introducePage_bg.png) no-repeat top left;
  background-size: 100% auto;
  background-color: #dfeeff;

  /* 兼容 iOS >= 11.2 */
  // .infoBox {
  //   .main-l {
  //     .video {
  //       width: 100%;
  //       height: 400px;
  //       #box {
  //         width: 100%;
  //         height: 100%;
  //       }
  //       border-radius: 32px;
  //       box-sizing: border-box;
  //       position: relative;
  //       #J_prismPlayer video {
  //         border-radius: 32px;
  //       }
  //       video {
  //         box-sizing: border-box;
  //         border-radius: 32px;
  //       }
  //       .lock {
  //         position: absolute;
  //         width: 100%;
  //         height: 100%;
  //         z-index: 999;
  //         top: 0;
  //         left: 0;
  //         border-radius: 32px;
  //         background: rgba(0, 0, 0, 0.2);
  //         .name {
  //           position: absolute;
  //           top: 22px;
  //           left: 26px;
  //           font-size: 28px;
  //           font-weight: 400;
  //           color: #ffffff;
  //           line-height: 40px;
  //         }
  //         .img {
  //           width: 48px;
  //           height: 48px;
  //           margin: 140px auto 18px;
  //           img {
  //             width: 100%;
  //             height: 100%;
  //           }
  //         }
  //         .txt {
  //           font-size: 40px;
  //           text-align: center;
  //           font-weight: 600;
  //           color: #ffffff;
  //           line-height: 56px;
  //         }
  //       }
  //     }
  //   }
  // }
  // video {
  //   // display: none;
  // }
  .section-aside {
    position: absolute;
    top: 160px;
    left: 36px;
    font-size: 72px;
    color: #ffffff;
    line-height: 84px;
    text-shadow: 5px 2px #4a7196;
    p:last-child {
      padding-left: 40px;
    }
  }
  .section-main {
    box-sizing: border-box;
    padding: 38px 30px 0px;
    width: 100%;
    background: url(./img/introducePage_across.png) no-repeat top center;
    background-size: calc(100% - 20px) 80px;
    .main-content {
      // width: 100%;
      padding: 50px 30px 56px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      font-size: 28px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      // text-align-last: justify;
      text-align: justify;
      text-indent: 2em;
    }
    .main-picture {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 68px 22px 94px;
      img {
        width: 100%;
        // width: 646px;
        // height: 486px;
      }
      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -68px;
        left: 28px;
        width: 634px;
        height: 106px;
        background: url(./img/introducePage_vertical.png) no-repeat top center;
        background-size: 100% 100%;
        z-index: 1;
      }
    }
    .main-video {
      position: relative;
      margin-top: 30px;
      background-color: #fff;
      border-radius: 0 0 6px 6px;
      padding: 40px 22px 40px;
      text-align: center;
      height: 100px;
      ::v-deep video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        background: #fff;
      }
    }
  }
}
</style>
